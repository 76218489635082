import { $authHost, $host } from '.'

export const register = async (
	firstName,
	lastName,
	middleName,
	email,
	password,
	confirmPassword,
	loginphone,
	username,
	telegramId,
	langId
) => {
	const { data } = await $host.post('auth/register', {
		firstName,
		lastName,
		middleName,
		email,
		password,
		confirmPassword,
		loginphone,
		username,
		telegramId,
		langId
	})
	return data
}

// export const login = async (number, password) => {
// 	const { data } = await $host.post('auth/login', {
// 		number,
// 		password
// 	})
// 	return data
// }

export const check = async (telegramId) => {
	const { data } = await $authHost.post('auth/tg-check', {
		telegramId
	})
	return data
}

export const tgLogin = async (telegramId) => {
	const { data } = await $authHost.post(
		'auth/tg-login',
		{
			telegramId
		},
		{ withCredentials: true }
	)
	return data
}

export const checkUsername = async (username) => {
	const { data } = await $authHost.post(
		'auth/check-username',
		{
			username
		},
		{ withCredentials: true }
	)
	return data
}

export const refresh = async () => {
	const { data } = await $authHost.get('auth/refresh', {
		withCredentials: true
	})
	return data
}

export const updatePassword = async (oldpassword, newpassword) => {
	const { data } = await $authHost.put('auth/update/password', {
		oldpassword,
		newpassword
	})
	return data
}

export const changeLang = async (language) => {
	const { data } = await $authHost.put('auth/change-lang', {
		language
	})
	return data
}

export const uploadImage = async (form) => {
	const { data } = await $authHost.post('auth/image', form, {
		headers: {
			'Content-Type': 'multipart/form-data'
		}
	})
	return data
}

export const changeUserInfo = async ({
	firstName,
	middleName,
	lastName
}) => {
	const { data } = await $authHost.put('auth/change-userinfo', {
		firstName,
		middleName,
		lastName
	})
	return data
}

export const changeUserNumber = async ({ loginphone }) => {
	const { data } = await $authHost.put('auth/change-number', {
		loginphone
	})
	return data
}

export const changeUserName = async ({ username }) => {
	const { data } = await $authHost.put('auth/change-username', {
		username
	})
	return data
}

export const isAuthActionsChangable = async () => {
	const { data } = await $authHost.get('auth/check-auth-actions')
	return data
}
