import React, { useEffect, useState } from 'react'
import { Modal, ModalBody, ModalFooter } from 'react-bootstrap'
import { recepientStore } from '../store/recepientStore'
import {
	FrownOutlined,
	MehOutlined,
	SmileOutlined
} from '@ant-design/icons'
import { Flex, Rate } from 'antd'
import '../pages/css/DoneModal.scss'
import { useTranslation } from 'react-i18next'

export const DoneModal = () => {
	const { t } = useTranslation()

	const [doneModal, setDoneModal] = useState(false)

	const [rating, setRating] = useState(0)
	const [comment, setComment] = useState('')

	const { recepients, sendReview, doneRecepient } = recepientStore()

	const submitFc = () => {
		const queueScheme = recepients.find(
			(q) => q.queues[0].recepients[0].status === 'done'
		)

		const recepient = queueScheme.queues[0].recepients.find(
			(r) => r.status === 'done'
		)

		sendReview({
			queueSchemeId: queueScheme.id,
			queueId: queueScheme.queues[0].id,
			recepientId: recepient.id,
			providerId: recepient.providerId,
			userId: recepient.userId,
			serviceId: recepient.serviceId,
			rating,
			comment
		})
		setDoneModal(false)
		setRating(0)
		setComment('')
		doneRecepient(
			queueScheme.id,
			queueScheme.queues[0].id,
			recepient.id
		)
	}
	useEffect(() => {
		const recepient = recepients.find(
			(q) => q.queues[0].recepients[0].status === 'done'
		)

		if (recepient) {
			setDoneModal(true)
		}
	}, [recepients])

	const customIcons = {
		1: <FrownOutlined />,
		2: <FrownOutlined />,
		3: <MehOutlined />,
		4: <SmileOutlined />,
		5: <SmileOutlined />
	}

	return (
		<Modal show={doneModal} centered>
			<ModalBody className="doneModalBody">
				<div
					className="doneModalText"
					style={{
						color: '#000'
					}}>
					<p>{t('YourQueueHasBeenDone')} !</p>
					<p>{t('PleaseRateService')}</p>
				</div>

				<Flex gap="middle" vertical>
					<Rate
						value={rating}
						onChange={setRating}
						className="reviewRate"
						defaultValue={0}
						character={({ index = 0 }) =>
							customIcons[index + 1]
						}
					/>
				</Flex>

				<textarea
					value={comment}
					onChange={(e) => setComment(e.target.value)}
					placeholder="Leave Your Comments...."
				/>
			</ModalBody>
			<ModalFooter>
				<button submit="" onClick={submitFc}>
					{t('Submit')}
				</button>
			</ModalFooter>
		</Modal>
	)
}
