import React from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'

export const About = () => {
	const { t } = useTranslation()

	return (
		<>
			<Helmet>
				<title>{t('About')} – Queue Expert</title>
				<meta name="description" content={t('AboutDesc')} />
			</Helmet>
			<div>About</div>
		</>
	)
}
