const tg = window?.Telegram?.WebApp

export const useTelegram = () => {
	const tgUser = tg?.initDataUnsafe?.user || {
		id: 910589946,
		first_name: 'Abdul Azeez',
		username: 'mielpl'
	}

	const onClose = () => {
		tg.close()
	}

	return {
		tg,
		tgUser,
		onClose
	}
}
