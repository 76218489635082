import moment from 'moment'
import { toDataURL } from 'qrcode'
import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import QROnCreate from '../components/modals/QROnCreate'
import './css/QueueOne.css'
import LogoSmall from './../assets/NavbatLogoSmall.svg'
import { queueSchemeStore } from '../store/queueSchemeStore'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

export const QueueOne = () => {
	const [waiting] = useState()
	const [imgQr, setImgQr] = useState()
	const [show, setShow] = useState(false)
	const { id } = useParams()
	const { t } = useTranslation()

	const { queueScheme } = queueSchemeStore()
	const onequeue = queueScheme.find((data) => data.id === id)

	useEffect(() => {
		if (id.match(/\D|^0/g)) {
			return // history.push(PAGE_404_ROUTE)
		}
		// QOne(id).then((data) => {
		// 	!data
		// 		? toast.error('Очередь не существует') &&
		// 		  history.push(PAGE_404_ROUTE)
		// 		: setOnequeue(data)
		// })
		// QCountClient(id).then((data) => {
		// 	setWaiting(data)
		// })
		return
	}, [id])

	// useEffect(() => {
	// socket.connect()
	// socket.on('nextPress', (next) => {
	// 	if (onequeue.id === next[1]) {
	// 		return setOnequeue({
	// 			...onequeue,
	// 			activeClient: next[0]
	// 		})
	// 	} else {
	// 		return
	// 	}
	// })
	// socket.on('statusPress', (status) => {
	// 	if (onequeue.id === status[1]) {
	// 		return setOnequeue({ ...onequeue, status: status[0] })
	// 	} else {
	// 		return
	// 	}
	// })
	// return () => {
	// 	socket.disconnect()
	// }
	// }, [, onequeue])

	const setQrFunc = async () => {
		try {
			toDataURL(
				process.env.REACT_APP_SITE_URL + 'result/' + id,
				{
					width: 150,
					margin: 0,
					color: {
						light: '#00000000'
					}
				}
			)
				.then((data) => {
					setImgQr(
						React.createElement('img', {
							src: data,
							alt: 'Your Qr Code',
							style: { width: '75%' }
						})
					)
				})
				.catch((e) => console.error(e))
			openModal()
		} catch (e) {
			toast.error(e.response.data.message)
		}
	}

	const closeModal = () => {
		setShow(false)
	}

	const openModal = () => {
		setShow(true)
	}

	return (
		<>
			<Helmet>
				<title>{t('Queue')} – Queue Expert</title>
				<meta name="description" content={t('QueueDesc')} />
			</Helmet>
			<header>
				{/*'active,waiting,paused'
					.split(',')
					.includes(onequeue.status)*/}
				{onequeue?.isActive ? (
					<>
						<Link
							to={`/queue/${onequeue.id}/clients`}
							className="clientsBtn">
							<img src={LogoSmall} alt="Clients" />
						</Link>
						<QROnCreate
							nodeName="i"
							attrs={{
								className:
									'las la-2x la-share-alt updateBtn'
							}}
							text=""
							onCreate={() => setQrFunc()}
							imgQr={imgQr}
							queueId={id}
							show={show}
							closeModal={closeModal}
							queue={onequeue}
						/>
					</>
				) : null}
				<h2 className="mainTopTitle">{t('Queue')}</h2>
			</header>
			<main className="queueOne">
				<h5>
					{onequeue.name === 'NoName'
						? t('NoName')
						: onequeue.name + ''}
				</h5>
				<div className="cabinetRow">
					<i className="la la-user la-2x"></i>
					<div className="pl-3 flex-grow-1">
						<div>{t('Queue')}</div>
						<div className="dark">{waiting} чел.</div>
					</div>
					<div className="pl-3">
						<div>Макс. длина</div>
						<div className="dark">{onequeue.maxsize}</div>
					</div>
				</div>
				<div className="cabinetRow">
					<i className="la la-calendar-plus la-2x "></i>
					<div className="pl-3 flex-grow-1">
						<div>Дата создания</div>
						<div className="dark">
							{moment(onequeue.createdAt).format(
								'DD.MM.yyyy'
							)}
						</div>
					</div>
					<div className="pl-3">
						<div>Дата окончания</div>
						<div className="dark">
							{moment(onequeue.finishDate).format(
								'DD.MM.yyyy'
							)}
						</div>
					</div>
				</div>
				<div className="cabinetRow">
					<i className="la la-clock la-2x"></i>
					<div className="pl-3">
						<div>Часы работы</div>
						<div className="dark">
							{moment(onequeue.startDate).format(
								'HH:mm'
							)}{' '}
							-{' '}
							{moment(onequeue.finishDate).format(
								'HH:mm'
							)}
						</div>
						{onequeue.startTime && onequeue.finishTime ? (
							<div>
								<div>Обед</div>{' '}
								<p className="dark">
									{moment(
										onequeue.startTime
									).format('HH:mm')}{' '}
									-{' '}
									{moment(
										onequeue.finishTime
									).format('HH:mm')}
								</p>
							</div>
						) : null}
					</div>
				</div>
				<div className="cabinetRow border-0">
					<i className="la la-info la-2x "></i>
					<div className="pl-3">
						<div>Заметка</div>
						<div className="dark">{onequeue.message}</div>
					</div>
				</div>
				<div className="cabinetRow m-auto border-0">
					<figcaption>
						{/* {onequeue.activeClient < onequeue.maxsize &&
						onequeue.status !== 'finished' &&
						onequeue.status !== 'canceled' &&
						moment(onequeue.startDate) < moment() ? ( */}
						{/* <>
								<div>Текущий №</div>
								<div className="dark font-size-5 text-center">
									{onequeue.activeClient}
								</div>
							</> */}
						{/* ) : moment(onequeue.startDate) > moment() ? (
							''
						) : ( */}
						<div className="dark font-size-3 text-center">
							Этот очередь уже завершено
						</div>
						{/* )} */}
					</figcaption>
				</div>
				{1 < onequeue.maxsize &&
				onequeue.status !== 'finished' &&
				onequeue.status !== 'canceled' ? (
					<>
						{onequeue.status === 'active' ? (
							<nav className="d-flex">
								<button
									className="queueBtn bordered QONextBtn"
									// onClick={QNextClick}
								>
									<i className="la la-arrow-right la-2x "></i>
									Следующий
								</button>
							</nav>
						) : (
							''
						)}
						<nav className="d-flex w-45">
							{onequeue.status === 'active' ? (
								<button
									className="queueBtn wait mr-3"
									// onClick={QPause}
								>
									<i className="la la-hourglass la-2x"></i>
									Перерыв
								</button>
							) : moment(onequeue.startDate) >
							  moment() ? (
								<div className="queueBtn bordered mr-3">
									Очередь начнется{' '}
									{moment(
										onequeue.startDate
									).fromNow()}
								</div>
							) : (
								<button
									className="queueBtn active mr-3"
									// onClick={QActive}
								>
									<i className="las la-check-square la-2x"></i>
									Активировать
								</button>
							)}
							<button
								className="queueBtn error"
								// onClick={QOff}
							>
								<i className="la la-arrow-right la-2x"></i>
								Завершить
							</button>
						</nav>
					</>
				) : (
					''
				)}
			</main>
		</>
	)
}
