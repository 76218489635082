import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { queueSchemeStore } from '../store/queueSchemeStore'
import { authStore } from '../store/authStore'
import { OBSERVE_ROUTE } from '../util/routenames'
import { toast } from 'react-toastify'

export const RedirectWithCode = () => {
	const { code } = useParams()
	const navigate = useNavigate()
	const { t } = useTranslation()
	const { checkQueueScheme } = queueSchemeStore()
	const { user } = authStore()

	useEffect(() => {
		const fetchData = async () => {
			const data = await checkQueueScheme(code)
			if (
				!data ||
				data === 'null' ||
				data.message === 'NotFound'
			) {
				toast.error(t('QueueSchemeNotFound'))
			} else if (data.message === 'Ok') {
				navigate(OBSERVE_ROUTE + '/' + data.id)
			} else if (data.message === 'InvalidUnique') {
				toast.error(t('QueueSchemeInvalidUnique'))
			} else if (data.message === 'OwnQueue') {
				// navigate(QUEUE_SCHEME_ROUTE + '/' + data.id)
			}
		}
		fetchData()
	}, [checkQueueScheme, code, user, navigate, t])

	return (
		<div>
			{t('RedirectingToQueueWithCode')} {code}
		</div>
	)
}
