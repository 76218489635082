import { useTranslation } from 'react-i18next'
import { useTelegram } from '../components/telegram'
import { authStore } from '../store/authStore'
import { Link } from 'react-router-dom'
import {
	CHANGE_LANGUAGE_ROUTE,
	HISTORY_ROUTE
} from '../util/routenames'
import IconVer from '../assets/iconVerNew.png'
import { useState } from 'react'
import { ChangeUserInfoModal } from '../components/ChangeUserInfoModal'
import { ChangeNumberModal } from '../components/ChangeNumberModal'
import { ChangeUserNameModal } from '../components/ChangeUserNameModal'
import { Helmet } from 'react-helmet'

export const Profile = () => {
	const { t } = useTranslation()
	const { tg } = useTelegram()
	const { user, uploadImage } = authStore()

	const [image, setImage] = useState()
	const [handleModal, setHandleModal] = useState(false)
	const [handleNumber, setHandleNumber] = useState(false)
	const [handleUName, setHandleUName] = useState(false)

	const logOut = () => {
		// user.setUser({})
		// user.setName()
		// user.setlastVisit()
		// user.setIsAuth(false)
		// localStorage.clear()
		// history.push(LOGIN_ROUTE)
		// document.body.scrollTop =
		// 	document.documentElement.scrollTop = 0

		tg.close()
	}

	const uploadImageFc = async (images) => {
		if (!images?.length) return
		const formData = new FormData()
		formData.append('avatar', images[0])

		await uploadImage(formData)

		setImage(URL.createObjectURL(images[0]))
	}

	return (
		<>
			{handleModal ? (
				<ChangeUserInfoModal
					handleModal={handleModal}
					setHandleModal={setHandleModal}
				/>
			) : (
				''
			)}
			{handleNumber ? (
				<ChangeNumberModal
					handleNumber={handleNumber}
					setHandleNumber={setHandleNumber}
				/>
			) : (
				''
			)}
			{handleUName ? (
				<ChangeUserNameModal
					handleUName={handleUName}
					setHandleUName={setHandleUName}
				/>
			) : (
				''
			)}
			<Helmet>
				<title>@{user.username} – Queue Expert</title>
				<meta name="description" content={t('ProfileDesc')} />
			</Helmet>
			<header>
				<h2 className="mainTopTitle">{t('Profile')}</h2>
			</header>
			<main className="CabMain">
				<div className="w-100 cabinetRow">
					{user.avatar ? (
						<label className="cabinetImgContainer">
							<img
								className="cabinetImg"
								src={
									image
										? image
										: `https://ewp-test.s3.ap-southeast-1.amazonaws.com/${user.avatar}`
								}
								alt={user.firstName + "'s photo"}
							/>
							<input
								hidden
								type="file"
								accept="image/*"
								onChange={(e) =>
									uploadImageFc(e.target.files)
								}
							/>
							<div className="cabinetFile">
								<i className="las la-camera"></i>
							</div>
						</label>
					) : (
						<i className="las la-user-tie la-3x"></i>
					)}
					<div className="pl-3">
						<div>{t('UserInfo')}</div>
						<div
							className="cabinetUser text-capitalize"
							onClick={() => setHandleModal(true)}>
							{user.firstName} {user.middleName}{' '}
							{user.lastName}
						</div>
					</div>
				</div>
				<div className="w-100 cabinetRow">
					<i className="la la-user la-2x"></i>
					<div className="pl-3">
						<div>{t('Username')}</div>
						<div
							className="cabinetUser"
							style={{
								display: 'flex',
								alignItems: 'center'
							}}
							onClick={() => setHandleUName(true)}>
							@{user.username}
							{user.isVerified ? (
								<img
									style={{
										marginLeft: '3px',
										width: '18px',
										height: '18px'
									}}
									src={IconVer}
									alt=""
								/>
							) : (
								''
							)}
						</div>
					</div>
				</div>
				<div className="w-100 cabinetRow ">
					<i className="las la-mobile la-2x "></i>
					<div className="pl-3 dark">
						{t('PhoneNumber')}:
					</div>
					<div
						className="cGreen"
						// onClick={() => setHandleNumber(true)} //FIX THIS
					>
						{user.loginphone}
					</div>
				</div>
				{/* <div className="w-100 cabinetRow dark pl-4">
					<div>{t('Tariff')}:</div>
					<div className="cGreen">FREE</div>
				</div> */}
				{/* <div>
					<button
						submit=""
						className="cGray"
						// onClick={() =>
						// 	history.push(CHANGE_TARIF_ROUTE)
						// }
					>
						{t('ChangeTariff')}
					</button>
				</div> */}
				<Link
					className="align-items-center cabinetRow"
					to={HISTORY_ROUTE}>
					<i className="la la-history la-2x"></i>
					<div className="pl-3 flex-grow-1 dark">
						<div>{t('MonitoringQueues')}</div>
					</div>
					<i className="la la-1x la-angle-right"></i>
				</Link>
				<Link
					to={CHANGE_LANGUAGE_ROUTE}
					className="align-items-center cabinetRow">
					<i className="las la-language la-2x"></i>
					<div className="pl-3 flex-grow-1 dark">
						{t('ChangeLanguage')}
					</div>
					<i className="la la-1x la-angle-right"></i>
				</Link>

				{/* <ModalPasswordForm /> */}
				<div className="mt-auto">
					<button
						className="cRed mb-3"
						submit=""
						onClick={logOut}>
						{t('Exit')}
					</button>
				</div>
			</main>
			<style>{`
			.CabMain {
				height: calc(var(--page-height) - 67px);
				display: flex;
				flex-direction: column;
				flex-grow: 1;
				margin: 0 1rem;
				overflow-y: scroll;
			}
			`}</style>
		</>
	)
}
