export const HOME_ROUTE = '/'
export const LOGIN_ROUTE = '/login'
export const REGISTER_ROUTE = '/register'
export const LOGOUT_ROUTE = '/logout'
export const PROFILE_ROUTE = '/profile'
export const HISTORY_ROUTE = '/monitoring'
export const QUEUE_SCHEME_ROUTE = '/queue-scheme'
export const CREATE_QUEUE_SCHEME_ROUTE = '/queue-scheme/create'
export const EDIT_QUEUE_SCHEME_ROUTE = '/queue-scheme/edit'
export const START_ROUTE = '/start'
export const CHANGE_TARIF_ROUTE = '/update/tarif'
export const UPDATE_ROUTE = '/update/queue'
export const QUEUE_CREATE_ROUTE = '/create'
export const RESULT_ROUTE = '/result'
export const OBSERVE_ROUTE = '/observe'
export const OBSERVE_SERVICES_ROUTE = '/observe/services'
export const ABOUT_ROUTE = '/about'
export const CONTACT_ROUTE = '/contact'
export const PRIVACY_ROUTE = '/privacy'
export const TERMS_ROUTE = '/terms'
export const NOT_FOUND_ROUTE = '/404'
export const PAGE_404_ROUTE = '/404'
export const PASS_RESET_PAGE = '/reset/pass'
export const CREATE_SERVICE_ROUTE = '/service'
export const ALL_SERVICES_ROUTE = '/services'
export const SERVICE_LIST_ROUTE = '/service-list'
export const QUEUE_START_ROUTE = '/queue-start'
export const CHANGE_LANGUAGE_ROUTE = '/profile/change-language'
export const REDIRECT_WITH_CODE = '/redirect'
