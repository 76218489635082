import React, { useEffect, useState } from 'react'
import './css/ServiceOne.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
	Button,
	ModalBody,
	ModalFooter,
	ModalTitle
} from 'react-bootstrap'
import Modal from 'react-bootstrap/Modal'
import { queueSchemeStore } from '../store/queueSchemeStore'
import { recepientStore } from '../store/recepientStore'
import ModalHeader from 'react-bootstrap/esm/ModalHeader'
import { HOME_ROUTE } from '../util/routenames'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'

export const ServiceOne = () => {
	const { id, serviceId } = useParams()
	const { t } = useTranslation()
	const navigate = useNavigate()

	const { getOneQueueSchemeFromClient } = queueSchemeStore()
	const { join, recepients } = recepientStore()

	const [s, setS] = useState(null)
	const [queueId, setQueueId] = useState(null)
	const [queueName, setQueueName] = useState('')
	const [isModalOpen, setIsModalOpen] = useState(false)

	const secondFormatter = (seconds) => {
		const hours = Math.floor(seconds / 3600)
		const minutes = Math.floor((seconds % 3600) / 60)

		const paddedHours = String(hours).padStart(2, '')
		const paddedMinutes = String(minutes).padStart(2, '0')

		return `${paddedHours}:${paddedMinutes}`
	}

	const navigateObserve = () => {
		navigate(HOME_ROUTE)
	}

	const joinFc = async () => {
		const { number } = await join({
			queueId,
			serviceId
		})

		navigateObserve()

		return toast.success(
			t('YouAreSuccessfullyJoinedTo') + ' ' + number
		)
	}

	const shouldShowJoin = () => {
		return !recepients.find((r) => r.id === id)
	}

	// useEffect(() => {
	// 	if (
	// 		!service.length ||`
	// 		!service.filter((s) => s.id === serviceId).length
	// 	) {
	// 		const fetchData = async () => {
	// 			await getServicesByQueueSchemeId(id)
	// 		}
	// 		fetchData()
	// 	}
	// }, [id, service, serviceId, getServicesByQueueSchemeId])

	// useEffect(() => {
	// 	if (!queueScheme?.services?.length) return
	// 	setS(queueScheme.services.find((ss) => ss.id))
	// }, [id, queueScheme.services, serviceId])

	useEffect(() => {
		const fetchData = async () => {
			const qs = await getOneQueueSchemeFromClient(id)

			setS(qs.services.find((s) => s.id === serviceId))

			if (!qs.queues.length) return

			setQueueId(qs.queues[0].id)

			setQueueName(qs.name)
		}
		fetchData()
	}, [getOneQueueSchemeFromClient, id, setS, serviceId])

	if (!s) return <></>

	return (
		<>
			<Helmet>
				<title>{t('ServiceNames')} – Queue Expert</title>
				<meta
					name="description"
					content={t('ServiceNamesDesc')}
				/>
			</Helmet>
			<header className="QCHeader headerFixed">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.go(-1)}></i>
				<h2 className="mainTopTitle">{t('ServiceNames')}</h2>
				<span></span>
			</header>
			<main className="serviceOneContainer">
				<Modal
					size="lg"
					show={isModalOpen}
					centered
					aria-labelledby="contained-modal-title-vcenter">
					<ModalHeader>
						<ModalTitle>
							{t('DoYouReallyWantToBeInLine')}
						</ModalTitle>
					</ModalHeader>
					<ModalBody>
						{t('YouAreSuccessfullyJoinedToQueue')}{' '}
						{queueName}
					</ModalBody>
					<ModalFooter>
						<div
							style={{
								width: '100%',
								display: 'flex',
								gap: '10px',
								justifyContent: 'center'
							}}>
							<Button
								onClick={() => setIsModalOpen(false)}
								submit="">
								{t('Close')}
							</Button>
							<Button onClick={joinFc} submit="">
								{t('Join')}
							</Button>
						</div>
					</ModalFooter>
				</Modal>
				<div className="serviceOneCard">
					<div className="serviceOneIcon">
						<i className="las la-landmark la-5x icon"></i>
						<p>{s.name}</p>
					</div>
					<div className="serviceOneTable">
						<div className="serviceOneTIcon">
							<i className="las la-clock la-2x icon"></i>
							<p className="serviceOneTableText">
								{t('Duration')} :
							</p>
						</div>
						<p className="serviceOneTableDuration">
							{secondFormatter(s.duration)}
						</p>
					</div>
					<div className="serviceOneTable">
						<div className="serviceOneTIcon">
							<i className="las la-money-bill-wave la-x icon la-2x icon"></i>
							<p className="serviceOneTableText">
								{t('Fee')} :
							</p>
						</div>
						<p className="serviceOneTableDuration">
							{s.fee} so'm
						</p>
					</div>
					<div className="serviceOneTable">
						<div className="serviceOneTIcon">
							<i className="las la-stream icon la-2x"></i>
							<p className="serviceOneTableText">
								{t('Description')} :
							</p>
						</div>
						<p className="serviceOneTableDuration">
							{s.description}
						</p>
					</div>
					<div className="serviceOneBT">
						{queueId ? (
							<>
								<Button
									size="lg"
									variant="outline-secondary"
									className="seviceBT1">
									{t('Watch')}
								</Button>

								<Button
									onClick={
										shouldShowJoin()
											? () =>
													setIsModalOpen(
														true
													)
											: navigateObserve
									}
									size="lg"
									variant={
										shouldShowJoin()
											? 'success'
											: 'outline-secondary'
									}
									className={
										'seviceBT' +
										(shouldShowJoin() ? 2 : 1)
									}>
									{shouldShowJoin()
										? t('Join')
										: t('Observe')}
								</Button>
							</>
						) : (
							<Button
								size="lg"
								variant="outline-secondary"
								className="seviceBT1">
								Notify me
							</Button>
						)}
					</div>
				</div>
			</main>
		</>
	)
}
