import { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import './css/Services.scss'
import { OBSERVE_ROUTE } from '../util/routenames'
import { queueSchemeStore } from '../store/queueSchemeStore'
import { useTranslation } from 'react-i18next'
import { Helmet } from 'react-helmet'

export const Services = () => {
	const { t } = useTranslation()

	const { queueScheme, getOneQueueSchemeFromClient } =
		queueSchemeStore()
	const { id } = useParams()

	const [qs, setQs] = useState(null)

	const secondFormatter = (seconds) => {
		const hours = Math.floor(seconds / 3600)
		const minutes = Math.floor((seconds % 3600) / 60)

		const paddedHours = String(hours).padStart(2, '')
		const paddedMinutes = String(minutes).padStart(2, '0')

		return `${paddedHours}:${paddedMinutes}`
	}

	useEffect(() => {
		setQs(queueScheme.find((qss) => qss.id === id))
	}, [id, queueScheme])

	useEffect(() => {
		const fetchData = async () => {
			setQs(await getOneQueueSchemeFromClient(id))
		}
		fetchData()
	}, [getOneQueueSchemeFromClient, id])

	if (!qs) return <></>

	return (
		<>
			<Helmet>
				<title>{t('Services')} – Queue Expert</title>
				<meta
					name="description"
					content={t('ServicesDesc')}
				/>
			</Helmet>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.go(-1)}></i>
				<h2 className="mainTopTitle">{t('Services')}</h2>
				<span></span>
			</header>
			<main className="servicesMain">
				{qs.services.map((ser) => {
					return (
						<Link
							to={
								OBSERVE_ROUTE +
								'/' +
								id +
								'/' +
								ser.id
							}
							key={ser.id}
							className="serviceBody1">
							<div className="serviceBody">
								<div className="serviceBody2">
									<div className="serviceCards">
										<i className="las la-landmark la-2x icon"></i>
										<div className="names">
											<p className="serviceName">
												{' '}
												{t('ServiceName')}
											</p>
											<p className="serviceProviderName">
												{ser.name}
											</p>
										</div>
									</div>
									<div className="serviceDuration">
										<p className="serviceDurationText">
											{t('Duration')}
										</p>
										<p className="serviceDurationNumber">
											{secondFormatter(
												ser.duration
											)}
										</p>
									</div>
								</div>
								<div className="serviceFee">
									<i className="las la-money-bill-wave la-x icon"></i>
									<p className="serviceFeeText">
										{t('Fee')}:
									</p>
									<span className="serviceFeeNumber">
										{ser.fee} sum
									</span>
								</div>
							</div>
						</Link>
					)
				})}
			</main>
		</>
	)
}
