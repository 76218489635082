import React, { useEffect, useState } from 'react'
import { Form, Spinner } from 'react-bootstrap'
import { authStore } from '../store/authStore'
import { useTelegram } from '../components/telegram'
import { useLocation, useNavigate } from 'react-router-dom'
import { HOME_ROUTE } from '../util/routenames'
import { useTranslation } from 'react-i18next'
import { languageStore } from '../store/languageStore'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import './css/Welcome.scss'

const schema = yup.object({
	firstName: yup
		.string()
		.required('First name is required field')
		.matches(
			/^[A-Za-zА-Яа-я ]+$/,
			'First name should contain only letters'
		),
	middleName: yup
		.string()
		.required('Middle name is required field')
		.matches(
			/^[A-Za-zА-Яа-я ]+$/,
			'Middle name should contain only letters'
		),
	lastName: yup
		.string()
		.required('Last name is required field')
		.matches(
			/^[A-Za-zА-Яа-я ]+$/,
			'Last name should contain only letters'
		),
	email: yup.string().email('Invalid email format'),
	password: yup
		.string()
		.required('Password is required field')
		.min(6, 'Password should be at least 6 characters long'),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref('password')], 'Password must be match')
})

export const Welcome = ({ setShow }) => {
	const {
		handleSubmit,
		register,
		formState: { errors }
	} = useForm({
		resolver: yupResolver(schema)
	})

	const [loginphone, setLoginphone] = useState('')
	const [loginphoneError, setLoginphoneError] = useState('')
	const [username, setUsername] = useState('')
	const [message, setMessage] = useState('')
	const [isNotCorrectUsername, setIsNotCorrectUsername] =
		useState(true)
	// const [messageUsername, setMessageUsername ] = useState()

	const navigate = useNavigate()
	const location = useLocation()
	const { tg, tgUser } = useTelegram()
	const { t, i18n } = useTranslation()
	const {
		register: registerFc,
		isNewAcc,
		checkUsername
	} = authStore()

	const { language } = languageStore()

	useEffect(() => {
		if (!isNewAcc) {
			navigate(HOME_ROUTE)
		}
	}, [isNewAcc, navigate])

	// useEffect(() => {
	// 	const delay = setTimeout(async () => {
	// 		if (usernameWatch && usernameWatch > 0) {
	// 			const data = await checkUsername(usernameWatch)
	// 		}
	// 	}, 1500)
	// 	return () => clearTimeout(delay)
	// }, [checkUsername, t, usernameWatch])

	useEffect(() => {
		const delay = setTimeout(async () => {
			if (username.length) {
				const { message } = await checkUsername(username)

				if (message === 'Available') {
					setIsNotCorrectUsername(false)
					setMessage('This username is available!')
				} else if (message === 'NotAvailable') {
					setIsNotCorrectUsername(false)
					setMessage('This username is already occupied')
				} else if (message === 'NotValid') {
					setIsNotCorrectUsername(false)
					setMessage(
						'Only a-z, 0-9, and underscores allowed'
					)
				} else if (message === 'InvalidLength') {
					setIsNotCorrectUsername(false)
					setMessage('This username is invalid')
				}
			}
		}, 1500)
		return () => clearTimeout(delay)
	}, [username, checkUsername])

	const handleUsernameChange = (e) => {
		setIsNotCorrectUsername(true)
		setUsername(e.target.value.toLowerCase())
	}

	const requestPhoneNumber = (e) => {
		e.preventDefault()
		tg.requestContact((success, response) => {
			if (response.status === 'sent' && success) {
				let phoneNumber =
					response.responseUnsafe.contact.phone_number
				if (!phoneNumber.startsWith('+')) {
					phoneNumber = `+${phoneNumber}`
				}
				setLoginphone(phoneNumber)
			} else if (response.status === 'cancelled' && !success) {
				setLoginphone('')
			}
		})
	}

	const onSubmit = async (e) => {
		e.preventDefault()

		await handleSubmit(async (data) => {
			if (!loginphone.length)
				return setLoginphoneError(
					'Phone number is a required field'
				)
			await registerFc({
				...data,
				telegramId: tgUser?.id,
				langId: language.find(
					(l) => l?.shortName === i18n.language
				)?.id,
				loginphone: loginphone.trim(),
				username: username.trim()
			})

			setShow(false)
			navigate(location.search.split('next=')[1] || HOME_ROUTE)
		})(e)
	}

	return (
		<>
			<Form onSubmit={onSubmit} id="registerForm">
				<Form.Group>
					<Form.Label>{t('FirstName')}</Form.Label>
					<Form.Control
						autoFocus
						id="firstName"
						type="text"
						placeholder="Enter your first name"
						// value={data.firstName}
						{...register('firstName')}
						// name="firstName"
						// onChange={onChange}
					/>
					<span className="error-message">
						{errors.firstName?.message}
					</span>
				</Form.Group>
				<Form.Group>
					<Form.Label>{t('MiddleName')}</Form.Label>
					<Form.Control
						id="middleName"
						type="text"
						placeholder="Enter your middle name"
						// value={data.middleName}
						{...register('middleName')}
						// name="middleName"
						// onChange={onChange}
					/>
					<span className="error-message">
						{errors.middleName?.message}
					</span>
				</Form.Group>
				<Form.Group>
					<Form.Label>{t('LastName')}</Form.Label>
					<Form.Control
						id="lastName"
						type="text"
						placeholder="Enter your last name"
						// value={data.lastName}
						{...register('lastName')}
						// name="lastName"
						// onChange={onChange}
					/>
					<span className="error-message">
						{errors.lastName?.message}
					</span>
				</Form.Group>
				<Form.Group>
					<Form.Label>{t('PhoneNumber')}</Form.Label>
					<div className="tgButton">
						<Form.Control
							id="loginphone"
							type="text"
							placeholder="Enter your phone number"
							value={loginphone}
							{...register('loginphone')}
							onChange={() => {}}
						/>
						<button onClick={requestPhoneNumber}>
							Telegram
						</button>
					</div>

					<span className="error-message">
						{errors.loginphone?.message ||
							loginphoneError}
					</span>
					<Form.Text className="text-muted">
						{t('NeverShareNumber')}
					</Form.Text>
				</Form.Group>
				<Form.Group>
					<Form.Label>{t('Username')}</Form.Label>
					<div className="welcomeUsername">
						<Form.Control
							id="username"
							type="text"
							placeholder="Enter your user name"
							onChange={handleUsernameChange}
						/>
						{isNotCorrectUsername && username.length ? (
							<div className="welcomeSpinner">
								<Spinner
									size="sm"
									animation={'border'}
								/>
							</div>
						) : (
							<></>
						)}
					</div>
					<span
						className={
							message.endsWith('!')
								? 'success-message'
								: 'error-message'
						}>
						{errors.username?.message || message}
					</span>
				</Form.Group>
				<Form.Group>
					<Form.Label>{t('Email')}</Form.Label>
					<Form.Control
						id="email"
						type="email"
						placeholder="Enter your email"
						// value={data.email}
						{...register('email')}
						// name="email"
						// onChange={onChange}
					/>
					<span className="error-message">
						{errors.email?.message}
					</span>
					<Form.Text className="text-muted">
						{t('NeverShareEmail')}
					</Form.Text>
				</Form.Group>
				<Form.Group>
					<Form.Label>{t('Password')}</Form.Label>
					<Form.Control
						id="password"
						type="password"
						placeholder="Password"
						// value={data.password}
						{...register('password')}
						// name="password"
						// onChange={onChange}
					/>
					<span className="error-message">
						{errors.password?.message}
					</span>
				</Form.Group>
				<Form.Group>
					<Form.Label>{t('ConfirmPassword')}</Form.Label>
					<Form.Control
						id="confirmPassword"
						type="password"
						placeholder="<PASSWORD>"
						// value={data.confirmPassword}
						{...register('confirmPassword')}
						// name="confirmPassword"
						// onChange={onChange}
					/>
					<span className="error-message">
						{errors.confirmPassword?.message}
					</span>
				</Form.Group>
			</Form>
		</>
	)
}
