import React, { useState } from 'react'
import './css/QueueCreate.scss'
import { useNavigate } from 'react-router-dom'
import {
	SERVICE_LIST_ROUTE,
	QUEUE_SCHEME_ROUTE
} from '../util/routenames'
import { languageStore } from '../store/languageStore'
import Dropdown from 'react-bootstrap/Dropdown'
import { useTranslation } from 'react-i18next'
import { queueSchemeStore } from '../store/queueSchemeStore'
import { authStore } from '../store/authStore'
import { Helmet } from 'react-helmet'

export const QueueCreate = () => {
	const navigate = useNavigate()
	const { t } = useTranslation()

	const [queue, setQueue] = useState({})
	const [timeSelectValue, setTimeSelectValue] = useState(5)
	const { language } = languageStore()
	const { user } = authStore()
	const { createQueueScheme } = queueSchemeStore()
	const [selectedLang, setSelectedLang] = useState(
		language.find((l) => l?.id === user.langId)
	)
	const defaultFunc = (e) => e.preventDefault()

	const submit = async () => {
		const { id } = await createQueueScheme({
			translation: queue,
			workdays
		})
		navigate(QUEUE_SCHEME_ROUTE + '/' + id + SERVICE_LIST_ROUTE)
	}
	const [workdays, setWorkdays] = useState({})

	const setTimeSelectValueFc = (e) => {
		setTimeSelectValue(+e.target.value)
		setWorkdays({})
	}

	const setWorkTimes = (workday, e, time, isOneDay) => {
		if (isOneDay) {
			return setWorkdays((old) => {
				const updatedWorkdays = { ...old }
				for (let i = 0; i < timeSelectValue; i++) {
					updatedWorkdays[i] = {
						...old[i],
						[time]: e.target.value
					}
				}
				return updatedWorkdays
			})
		}
		setWorkdays((old) => ({
			...old,
			[workday]: {
				...old[workday],
				[time]: e.target.value
			}
		}))
	}

	return (
		<form onSubmit={defaultFunc}>
			<Helmet>
				<title>{t('CreateQueue')} – Queue Expert</title>
				<meta
					name="description"
					content={t('CreateQueueDesc')}
				/>
			</Helmet>
			<header className="QCHeader">
				<i
					className="la la-angle-left la-2x"
					onClick={() => window.history.back()}></i>
				<h2 className="mainTopTitle flex-grow-1">
					{t('CreateQueue')}
				</h2>
			</header>
			<main className="QCMain">
				<label>
					<span>{t('QueueName')}</span>
					<div className="inputDiv">
						<input
							value={queue[selectedLang.id]?.name || ''}
							onChange={(e) => {
								setQueue((old) => ({
									...old,
									[selectedLang.id]: {
										...old[selectedLang.id],
										name: e.target.value
									}
								}))
							}}
						/>

						<Dropdown className="dropdown">
							<Dropdown.Toggle
								variant="error"
								id="dropdown-basic">
								<img
									src={selectedLang.flag}
									style={{
										height: '40px'
									}}
									alt="flag"
								/>
							</Dropdown.Toggle>

							<Dropdown.Menu>
								{language.map((lang) => (
									<Dropdown.Item
										className="dropdownitem"
										onClick={() =>
											setSelectedLang(lang)
										}
										key={lang.id}>
										{lang.name}
										<img
											src={lang.flag}
											alt="flag"
										/>
									</Dropdown.Item>
								))}
							</Dropdown.Menu>
						</Dropdown>
					</div>
				</label>

				<label>
					<span>{t('Description')}</span>
					<input
						className="textarea"
						value={
							queue[selectedLang.id]?.description || ''
						}
						onChange={(e) => {
							setQueue((old) => ({
								...old,
								[selectedLang.id]: {
									...old[selectedLang.id],
									description: e.target.value
								}
							}))
						}}
					/>
				</label>
				<form>
					<select
						aria-label="Default select example"
						value={timeSelectValue}
						onChange={setTimeSelectValueFc}>
						<option value={5}>{t('MonFri')}</option>
						<option value={6}>{t('MonSat')}</option>
						<option value={7}>{t('Everday')}</option>
						<option value={4}>{t('Custimize')}</option>
					</select>
					<div>
						{timeSelectValue !== 4 ? (
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									gap: 20
								}}>
								<label
									style={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'row',
										flexGrow: 1
									}}>
									{t('Start')}
									<input
										type="time"
										value={
											workdays[0]?.startTime ||
											''
										}
										onChange={(e) =>
											setWorkTimes(
												0,
												e,
												'startTime',
												true
											)
										}
									/>
								</label>
								<label
									style={{
										display: 'flex',
										alignItems: 'center',
										flexDirection: 'row',
										flexGrow: 1
									}}>
									{t('End')}
									<input
										type="time"
										value={
											workdays[0]?.finishTime ||
											''
										}
										onChange={(e) =>
											setWorkTimes(
												0,
												e,
												'finishTime',
												true
											)
										}
									/>
								</label>
							</div>
						) : (
							<>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: 20
									}}>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('Start')}
										<input
											type="time"
											value={
												workdays[0]
													?.startTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													0,
													e,
													'startTime'
												)
											}
										/>
									</label>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('End')}
										<input
											type="time"
											value={
												workdays[0]
													?.finishTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													0,
													e,
													'finishTime'
												)
											}
										/>
									</label>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: 20
									}}>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('Start')}
										<input
											type="time"
											value={
												workdays[1]
													?.startTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													1,
													e,
													'startTime'
												)
											}
										/>
									</label>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('End')}
										<input
											type="time"
											value={
												workdays[1]
													?.finishTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													1,
													e,
													'finishTime'
												)
											}
										/>
									</label>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: 20
									}}>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('Start')}
										<input
											type="time"
											value={
												workdays[2]
													?.startTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													2,
													e,
													'startTime'
												)
											}
										/>
									</label>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('End')}
										<input
											type="time"
											value={
												workdays[2]
													?.finishTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													2,
													e,
													'finishTime'
												)
											}
										/>
									</label>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: 20
									}}>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('Start')}
										<input
											type="time"
											value={
												workdays[3]
													?.startTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													3,
													e,
													'startTime'
												)
											}
										/>
									</label>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('End')}
										<input
											type="time"
											value={
												workdays[3]
													?.finishTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													3,
													e,
													'finishTime'
												)
											}
										/>
									</label>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: 20
									}}>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('Start')}
										<input
											type="time"
											value={
												workdays[4]
													?.startTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													4,
													e,
													'startTime'
												)
											}
										/>
									</label>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('End')}
										<input
											type="time"
											value={
												workdays[4]
													?.finishTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													4,
													e,
													'finishTime'
												)
											}
										/>
									</label>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: 20
									}}>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('Start')}
										<input
											type="time"
											value={
												workdays[5]
													?.startTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													5,
													e,
													'startTime'
												)
											}
										/>
									</label>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('End')}
										<input
											type="time"
											value={
												workdays[5]
													?.finishTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													5,
													e,
													'finishTime'
												)
											}
										/>
									</label>
								</div>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: 20
									}}>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('Start')}
										<input
											type="time"
											value={
												workdays[6]
													?.startTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													6,
													e,
													'startTime'
												)
											}
										/>
									</label>
									<label
										style={{
											display: 'flex',
											alignItems: 'center',
											flexDirection: 'row',
											flexGrow: 1
										}}>
										{t('End')}
										<input
											type="time"
											value={
												workdays[6]
													?.finishTime || ''
											}
											onChange={(e) =>
												setWorkTimes(
													6,
													e,
													'finishTime'
												)
											}
										/>
									</label>
								</div>
							</>
						)}
					</div>
				</form>
				<button
					className="btnSubmit"
					submit=""
					onClick={submit}>
					{t('Next')}
				</button>
			</main>
		</form>
	)
}
