import { create } from 'zustand'
import {
	check,
	refresh,
	register,
	tgLogin,
	changeLang,
	checkUsername,
	uploadImage,
	changeUserInfo,
	changeUserNumber,
	changeUserName,
	isAuthActionsChangable
} from '../http/userAPI'

export const authStore = create((set) => ({
	isLoggedIn: false,
	user: null,
	isFetched: false,
	isNewAcc: false,
	isNewAccFetched: false,
	isAuthChangesDisabled: [],

	check: async (telegramId) => {
		set({ isNewAccFetched: false })
		const data = await check(telegramId)
		if (data?.isNew)
			return set({
				isLoggedIn: true,
				isNewAcc: true,
				isNewAccFetched: true
			})

		const { isNew, ...user } = data
		set({
			isLoggedIn: true,
			isNewAcc: false,
			isNewAccFetched: true,
			user
		})
		return data
	},

	tgLogin: async (telegramId) => {
		set({ isFetched: false })
		await tgLogin(telegramId)
		set({
			isLoggedIn: true,
			isFetched: true
		})
	},

	checkUsername: async (username) => {
		const message = await checkUsername(username)
		return message
	},

	refresh: async () => {
		set({ isFetched: false })
		const user = await refresh()
		set({ isLoggedIn: true, isFetched: true, user })
	},

	register: async ({
		firstName,
		lastName,
		middleName,
		email,
		password,
		confirmPassword,
		loginphone,
		username,
		telegramId,
		langId
	}) => {
		set({ isFetched: false })
		const data = await register(
			firstName,
			lastName,
			middleName,
			email,
			password,
			confirmPassword,
			loginphone,
			username,
			telegramId,
			langId
		)
		set({
			isLoggedIn: true,
			user: data,
			isFetched: true,
			isNewAcc: false
		})
	},

	changeLang: async (langId) => {
		await changeLang(langId)
		set((old) => ({
			...old,
			user: { ...old.user, langId }
		}))
	},

	uploadImage: async (form) => {
		const image = await uploadImage(form)
		return image
	},

	changeUserInfo: async ({ firstName, middleName, lastName }) => {
		await changeUserInfo({
			firstName,
			middleName,
			lastName
		})

		set((state) => {
			const today = new Date()

			if (!state.user) return state
			return {
				...state,
				user: {
					...state.user,
					firstName,
					middleName,
					lastName
				},
				isAuthChangesDisabled: [
					...state.isAuthChangesDisabled,
					{
						type: 'namesChange',
						nextAvailableTime: new Date(
							today.setDate(today.getDate() + 7)
						).toString()
					}
				]
			}
		})
	},

	changeUserNumber: async ({ loginphone }) => {
		await changeUserNumber({
			loginphone
		})

		set((state) => ({
			...state,
			user: {
				...state.user,
				loginphone
			}
		}))
	},

	changeUserName: async ({ username }) => {
		await changeUserName({
			username
		})

		set((state) => {
			const today = new Date()

			if (!state.user) return state
			return {
				...state,
				user: {
					...state.user,
					username
				},
				isAuthChangesDisabled: [
					...state.isAuthChangesDisabled,
					{
						type: 'usernameChange',
						nextAvailableTime: new Date(
							today.setDate(today.getDate() + 7)
						).toString()
					}
				]
			}
		})
	},

	isAuthActionsChangable: async () => {
		const isAuthChangesDisabled = await isAuthActionsChangable()

		set((state) => {
			return {
				...state,
				isAuthChangesDisabled
			}
		})
	}
}))
