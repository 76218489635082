import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { ModalBody, ModalFooter } from 'react-bootstrap'
import { recepientStore } from '../store/recepientStore'
import { toast } from 'react-toastify'
import { queueSchemeStore } from '../store/queueSchemeStore'
import '../pages/css/AppModal.scss'
import { useTranslation } from 'react-i18next'

export const AppModal = ({ recepientId }) => {
	const { t } = useTranslation()

	const [appModalOpen, setAppModalOpen] = useState({
		isOpen: false,
		recepientId: null
	})
	const [callingRecepient, setCallingRecepient] = useState()
	const [cancelModal, setCancelModal] = useState(false)
	const [skipModal, setSkipModal] = useState(false)

	const { recepients, finishRecepient, skipRecepient } =
		recepientStore()

	const { skipRecepient: qSkipRecepient } = queueSchemeStore()

	const goBackOrCancelFc = () => {
		if (cancelModal || skipModal) {
			setCancelModal(false)
			setSkipModal(false)
		} else {
			setCancelModal(true)
		}
	}

	const submitOrSkipFc = () => {
		if (skipModal) {
			skipRecepientFc()
		} else if (cancelModal) {
			exitFunction()
		} else {
			setSkipModal(true)
		}
	}

	const exitFunction = async () => {
		try {
			await finishRecepient(
				callingRecepient.id,
				callingRecepient.queues[0].id,
				callingRecepient.queues[0].recepients[0].id
			)
			setAppModalOpen({ isOpen: false, recepientId: null })
		} catch (e) {
			toast.error(e.response.data.message)
		}
	}

	const skipRecepientFc = async () => {
		const [number, status] = await skipRecepient(
			callingRecepient.id,
			callingRecepient.queues[0].id,
			callingRecepient.queues[0].recepients[0].id
		)
		await qSkipRecepient(
			callingRecepient.id,
			callingRecepient.queues[0].recepients[0].id,
			number,
			status
		)
		setCallingRecepient(false)
	}

	const imHereFC = () => {
		setAppModalOpen({ isOpen: false, recepientId: null })
	}

	useEffect(() => {
		const recepient = recepients.find(
			(q) => q.queues[0].recepients[0].status === 'calling'
		)

		const shouldClose = recepients.some(
			(q) => q.queues[0].recepients[0].id === recepientId
		)
		if (
			recepientId &&
			shouldClose &&
			appModalOpen.recepientId === recepientId
		) {
			setAppModalOpen({
				isOpen: false,
				recepientId: null
			})
		}

		if (recepient) {
			setAppModalOpen({
				isOpen: true,
				recepientId: recepient.queues[0].recepients[0].id
			})
			setCallingRecepient(recepient)
		}
	}, [recepients, appModalOpen.recepientId, recepientId])

	if (!callingRecepient) return <></>

	return (
		<Modal show={appModalOpen.isOpen} centered>
			<ModalBody>
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						gap: ' 10px'
					}}>
					<span className="appModalText">
						{skipModal
							? t('DoYouWantToSkip')
							: cancelModal
								? t('DoYouWantToCancelYourQueue')
								: t('YourTurnNowAreYouHere')}
					</span>
					<div className="imHereButtonDiv">
						<button
							className="ImHereButton"
							onClick={imHereFC}>
							<span className="imHereBtText">
								{t('ImHere')}
							</span>
						</button>
					</div>
				</div>
			</ModalBody>
			<ModalFooter>
				<div
					style={{
						display: 'flex',
						width: '100%',
						gap: '10px'
					}}>
					<button
						submit=""
						onClick={() => goBackOrCancelFc()}>
						{skipModal || cancelModal
							? t('GoBack')
							: t('Cancel')}
					</button>
					<button
						submit=""
						onClick={() => submitOrSkipFc()}>
						{skipModal
							? t('Skip')
							: cancelModal
								? t('Cancel')
								: t('Skip')}
					</button>
				</div>
			</ModalFooter>
		</Modal>
	)
}
