import { create } from 'zustand'
import {
	finishRecepient,
	getRecepientHistory,
	// getRecepients,
	getRecepientsSchemes,
	join,
	sendReview,
	skipRecepient
} from '../http/recepientApi'
import { queueSchemeStore } from './queueSchemeStore'

export const recepientStore = create((set) => ({
	recepients: [],
	recepientHistory: [],
	isRecepientsFetched: false,

	// getRecepients: async () => {
	// 	const recepients = recepientStore.getState().recepient

	// 	if (
	// 		recepients.length ||
	// 		recepientStore.getState().isRecepientsFetched
	// 	)
	// 		return recepients

	// 	const recepient = await getRecepients()
	// 	set({ recepient, isRecepientsFetched: true })
	// },
	getRecepientsSchemes: async () => {
		const recepient = recepientStore.getState().recepients

		if (
			recepient.length ||
			recepientStore.getState().isRecepientsFetcheds
		)
			return recepient

		const recepients = await getRecepientsSchemes()
		set({ recepients, isRecepientsFetched: true })
	},
	join: async (data) => {
		const recepient = await join(data)

		// const qs = queueSchemeStore
		// 	.getState()
		// 	.queueScheme.find(
		// 		(qsss) => qsss.id === recepient.queueSchemeId
		// 	)

		// const resRecepient = { ...qs }
		// resRecepient.queues[0].recepients = [recepient]
		// resRecepient.queues[0].recepientsCount =
		// 	+resRecepient.queues[0].recepientsCount + 1

		// set((state) => {
		// 	return {
		// 		...state,
		// 		recepients: [...state.recepients, resRecepient]
		// 	}
		// })
		return recepient
	},

	setJoinClient: (queueScheme, data) => {
		const recepient = {
			status: 'pending',
			isDeleted: false,
			queueId: data.queueId,
			serviceId: data.serviceId,
			userId: data.userId,
			number: data.number,
			id: data.recepientId,
			queueSchemeId: data.queueSchemeId,
			updatedAt: new Date().toJSON(),
			createdAt: new Date().toJSON(),
			fee: null,
			providerId: data.providerId,
			start: null,
			finish: null,
			referredBy: null
		}

		const resRecepient = { ...queueScheme }
		resRecepient.queues[0].recepients = [recepient]
		resRecepient.queues[0].recepientsCount =
			+resRecepient.queues[0].recepientsCount + 1

		set((state) => {
			return {
				...state,
				recepients: [...state.recepients, resRecepient]
			}
		})
		return recepient
	},

	getRecepientHistory: async (page) => {
		const state = recepientStore.getState()
		const historyLength = state.recepientHistory.length
		const itemsPerPage = 10

		if (historyLength >= page * itemsPerPage) {
			return state.recepientHistory.slice(
				(page - 1) * itemsPerPage,
				page * itemsPerPage
			)
		}

		if (
			(historyLength &&
				historyLength / itemsPerPage < page - 1) ||
			(historyLength && historyLength / itemsPerPage < 1)
		) {
			return []
		}

		const history = await getRecepientHistory(page)

		set((state) => ({
			...state,
			recepientHistory: [...state.recepientHistory, ...history]
		}))

		return history
	},
	finishRecepient: async (queueSchemeId, queueId, recepientId) => {
		await finishRecepient(queueSchemeId, queueId, recepientId)
		set((state) => {
			return {
				...state,
				recepients: state.recepients.filter(
					(recepient) =>
						recepient.id !== queueSchemeId &&
						recepient.queues[0].id !== queueId &&
						recepient.queues[0].recepients[0].id !==
							recepientId
				)
			}
		})

		const queueScheme = queueSchemeStore
			.getState()
			.queueScheme.find((qs) => qs.id === queueSchemeId)

		if (queueScheme) {
			const newQueueScheme = {
				...queueScheme,
				queues: queueScheme.queues.map((q) => ({
					...q,
					recepientsCount: q.recepientsCount - 1
				}))
			}

			queueSchemeStore.setState({
				queueScheme: [
					...queueSchemeStore
						.getState()
						.queueScheme.filter(
							(qs) => qs.id !== queueSchemeId
						),
					newQueueScheme
				]
			})
		}
	},

	skipRecepient: async (
		queueSchemeId,
		queueId,
		recepientId,
		number
	) => {
		const data = await skipRecepient(
			queueSchemeId,
			queueId,
			recepientId
		)

		set((state) => {
			return {
				...state,
				recepients: state.recepients.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.map((q) => ({
								...q,
								recepients: q.recepients.map((r) => {
									if (r.id === recepientId) {
										return {
											...r,
											...(data.status
												? {
														status: data.status
													}
												: data.number
													? {
															status: 'pending',
															number: data.number
														}
													: {})
										}
									}
									return r
								})
							}))
						}
					}
					return qs
				})
			}
		})
		return [data.number ? data.number : number, data.status]
	},

	returnRecepient: async (queueSchemeId, queueId, recepientId) => {
		// const { message } = await returnRecepient(
		// 	queueSchemeId,
		// 	queueId,
		// 	recepientId
		// )

		// if (message === '') {
		// 	// FIX THIS
		// 	return
		// }

		set((state) => {
			return {
				...state,
				recepients: state.recepients.map((qs) => {
					if (queueSchemeId === qs.id) {
						return {
							...qs,
							queues: qs.queues.map((q) => ({
								...q,
								recepients: q.recepients.map((r) => {
									if (r.id === recepientId) {
										return {
											...r,
											status: 'pending'
										}
									}
									return r
								})
							}))
						}
					}
					return qs
				})
			}
		})
	},
	setQueueClientJoinFc: async ({ queueSchemeId }) => {
		set((state) => {
			const newData = state.recepients.map((qs) => {
				if (qs.id === queueSchemeId) {
					return {
						...qs,
						queues: [
							{
								...qs.queues[0],
								recepientsCount:
									+qs.queues[0].recepientsCount + 1
							}
						]
					}
				}
				return qs
			})
			return {
				...state,
				recepients: newData
			}
		})
	},
	setFinishRecepient: async ({
		queueSchemeId,
		queueId,
		recepientId
	}) => {
		set((state) => {
			return {
				...state,
				recepients: state.recepients.filter(
					(recepient) =>
						recepient.id !== queueSchemeId &&
						recepient.queues[0].id !== queueId &&
						recepient.queues[0].recepients[0].id !==
							recepientId
				)
			}
		})
	},
	sendReview: async (data) => {
		const review = await sendReview(data)

		set((state) => ({
			...state,
			recepientHistory: [
				...state.recepientHistory.map((r) => {
					if (r.id === data.recepientId) {
						return {
							...r,
							rate: data.rating,
							comment: data.comment
						}
					}
					return r
				})
			]
		}))

		return review
	},
	setCallingClient: (d) => {
		set((state) => {
			const newData = state.recepients.map((recepient) => {
				if (
					recepient.id === d.queueSchemeId &&
					recepient.queues[0].id === d.queueId &&
					recepient.queues[0].recepients[0].id ===
						d.recepientId
				) {
					return {
						...recepient,
						queues: [
							{
								...recepient.queues[0],
								recepients: [
									...recepient.queues[0].recepients.filter(
										(r) => r.id !== d.recepientId
									),
									{
										createdAt: new Date(),
										fee: null,
										finish: null,
										id: d.recepientId,
										isDeleted: false,
										number: d.active,
										providerId: d.providerId,
										queueId: d.queueId,
										queueSchemeId:
											d.queueSchemeId,
										referredBy: null,
										serviceId: d.next.serviceId,
										start: d.next.start,
										status: d.next.status,
										updatedAt: d.next.updatedAt,
										userId: d.next.User.id
									}
								]
							}
						]
					}
				}

				if (
					recepient.id === d.queueSchemeId &&
					recepient.queues[0].id === d.queueId &&
					recepient.queues[0].recepients[0].id ===
						d.previous
				) {
					return {
						...recepient,
						queues: [
							{
								...recepient.queues[0],
								recepients: [
									...recepient.queues[0].recepients.filter(
										(r) => r.id !== d.previous
									),
									{
										...recepient.queues[0].recepients.find(
											(r) => r.id === d.previous
										),
										status: 'done'
									}
								]
							}
						]
					}
				}

				return recepient
			})

			return {
				...state,
				recepients: newData
			}
		})
	},
	setActiveClient: ({ queueSchemeId, queueId, recepientId }) => {
		set((state) => {
			const newData = state.recepients.map((qs) => {
				if (
					qs.id === queueSchemeId &&
					qs.queues[0].id === queueId &&
					qs.queues[0].recepients[0].id === recepientId
				) {
					return {
						...qs,
						queues: [
							{
								...qs.queues[0],
								recepients: [
									{
										...qs.queues[0].recepients[0],
										status: 'active'
									}
								]
							}
						]
					}
				}
				return qs
			})

			return {
				...state,
				recepients: newData
			}
		})
	},
	doneRecepient: async (queueSchemeId, queueId, recepientId) => {
		set((state) => {
			return {
				...state,
				recepients: state.recepients.filter(
					(recepient) =>
						recepient.id !== queueSchemeId &&
						recepient.queues[0].id !== queueId &&
						recepient.queues[0].recepients[0].id !==
							recepientId
				)
			}
		})
	}
}))
