import { Suspense, useEffect, useMemo, useState } from 'react'
import './App.css'
import 'react-toastify/dist/ReactToastify.css'
import { useTelegram } from './components/telegram'
import { AppRouter } from './components/AppRouter'
import { Start } from './components/Start'
import NavBar from './components/Navbar'
import { languageStore } from './store/languageStore'
import { queueSchemeStore } from './store/queueSchemeStore'
import { useLocation, useNavigate } from 'react-router-dom'
import { authStore } from './store/authStore'
import Cookies from 'universal-cookie'
import { HOME_ROUTE, REDIRECT_WITH_CODE } from './util/routenames'
import i18n from './components/switchLanguage/i18n'
import { Flip, ToastContainer } from 'react-toastify'
import { recepientStore } from './store/recepientStore'
import { AppModal } from './components/AppModal'
import { DoneModal } from './components/DoneModal'
import { io } from 'socket.io-client'

function App() {
	const cookie = useMemo(() => new Cookies(), [])
	const { tg, tgUser } = useTelegram()
	const { getLanguage, language } = languageStore()
	const {
		getQueueSchemes,
		setQueueClientJoinFc: setQueueSClientJoinFc,
		getOneQueueSchemeFromClient
	} = queueSchemeStore()
	const {
		getRecepientsSchemes,
		setQueueClientJoinFc,
		setFinishRecepient,
		setJoinClient,
		setCallingClient,
		setActiveClient
	} = recepientStore()
	const {
		user,
		refresh,
		tgLogin,
		check,
		isNewAcc,
		isNewAccFetched,
		isLoggedIn,
		isAuthActionsChangable
	} = authStore()

	const [firstload, setFirstLoad] = useState(true)
	const [windowHeight, setWindowHeight] = useState()
	const [recepientIdForQueueHere, setRecepientIdForQueueHere] =
		useState(null)

	const navigate = useNavigate()
	const location = useLocation()

	useEffect(() => {
		i18n.on('languageChanged', (lang) => {
			// setLangId(language.find((l) => l.shortName === lang)?.id)
			localStorage.setItem(
				'lang',
				language.find((l) => l.shortName === lang)?.id
			)
		})
	}, [language])

	useEffect(() => {
		if (window?.Telegram?.WebApp) {
			tg.ready()
			tg.enableClosingConfirmation()
			tg.expand()
			tg.disableVerticalSwipes()

			const startParam = tg.initDataUnsafe.start_param
			if (startParam) {
				if (startParam.includes('code=')) {
					navigate(
						REDIRECT_WITH_CODE +
							'/' +
							startParam.split('code=')[1]
					)
				}
			}
		}
	}, [navigate, tg])

	setTimeout(() => {
		setFirstLoad(false)
	}, 250)

	const rootStyle = document.getElementById('navbat-root').style

	useEffect(() => {
		getLanguage()
	}, []) // eslint-disable-line

	useEffect(() => {
		const updateWindowDimensions = () => {
			const wh = window.innerHeight
			setWindowHeight(wh)
			rootStyle.setProperty(
				'--page-height',
				!tgUser.id ? wh + 'px' : wh - 80 + 'px'
			)
			rootStyle.height =
				rootStyle.getPropertyValue('--page-height')
		}

		updateWindowDimensions()

		window.addEventListener('resize', updateWindowDimensions)

		return () =>
			window.removeEventListener(
				'resize',
				updateWindowDimensions
			)
	}, [rootStyle, windowHeight, tgUser.id])

	useEffect(() => {
		const checkFc = async () => {
			if (!isNewAcc && !isNewAccFetched && !!language.length) {
				const data = await check(tgUser.id)
				if (!data) return
				// setLangId(data.langId)
				await i18n.changeLanguage(
					language.find((l) => l.id === data.langId)
						.shortName
				)
				if (localStorage.getItem('lang') === null) {
					localStorage.setItem('lang', data.langId)
				}
				getQueueSchemes()
				getRecepientsSchemes()
				isAuthActionsChangable()
			}
		}
		checkFc()
	}, [
		isNewAccFetched,
		isNewAcc,
		check,
		tgUser.id,
		language,
		getQueueSchemes,
		getRecepientsSchemes,
		isAuthActionsChangable
	])

	useEffect(() => {
		const checkFc = async () => {
			if (
				isNewAcc &&
				isNewAccFetched &&
				location.pathname !== HOME_ROUTE
			) {
				navigate(HOME_ROUTE + '?next=' + location.pathname)
			} else if (!isNewAcc && isNewAccFetched) {
				if (!cookie.get('access')) {
					if (!cookie.get('refresh')) {
						return await tgLogin(tgUser.id)
					}
					await refresh()
				}
			}
		}
		checkFc()
	}, [
		tgUser.id,
		navigate,
		location.pathname,
		tgLogin,
		refresh,
		isNewAcc,
		isNewAccFetched,
		cookie
	])

	useEffect(() => {
		if (!isLoggedIn) return

		const socket = io(process.env.REACT_APP_SOCKET_URL, {
			transports: ['polling'],
			withCredentials: true
		})

		socket.on('queue:join', async (data) => {
			if (data.userId !== user.id) return
			const queueScheme = await getOneQueueSchemeFromClient(
				data.queueSchemeId
			)

			setJoinClient(queueScheme, data)
		})

		socket.on('recepient:finish', (data) => {
			setFinishRecepient(data)
		})

		socket.on('queue:next', async (data) => {
			setCallingClient(data)
		})

		socket.on('queue:here', async (data) => {
			setRecepientIdForQueueHere(data.recepientId)
			setActiveClient(data)
		})

		return () => {
			socket.disconnect()
		}
	}, [
		isLoggedIn,
		setQueueClientJoinFc,
		setQueueSClientJoinFc,
		getOneQueueSchemeFromClient,
		setFinishRecepient,
		setJoinClient,
		setCallingClient,
		setActiveClient,
		user?.id
	])

	if (!language.length || !isNewAccFetched || firstload) {
		return <Start />
	}

	return (
		<Suspense fallback={<center>Loading...</center>}>
			<aside>
				<ToastContainer
					autoClose={5000}
					hideProgressBar
					transition={Flip}
				/>
			</aside>
			<AppRouter />
			<NavBar />
			<AppModal recepientId={recepientIdForQueueHere} />
			<DoneModal />
			{/* {!user && <NavBar />} */}
		</Suspense>
	)
}

export default App
